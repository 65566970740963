import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "results", "patientId"];

  connect = () => {
    this.inputTarget.addEventListener("input", this.search);
  };

  disconnect = () => {
    this.inputTarget.removeEventListener("input", this.search);
  };

  search = () => {
    const query = this.inputTarget.value.trim();

    if (query.length < 2) {
      this.resultsTarget.innerHTML = "";
      this.resultsTarget.classList.add("hidden");
      return;
    }

    // Get the `excludeFamilyMembers` from a data attribute
    const excludeFamilyMembers = this.element.dataset.patientSearchSelectExcludeFamilyMembers;
    const url = new URL("/patients/search", window.location.origin);
    url.searchParams.append("query", query);
    if (excludeFamilyMembers) {
      url.searchParams.append("exclude_family_members", excludeFamilyMembers);
    }

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          this.resultsTarget.innerHTML = this.buildResultsHtml(data);
          this.resultsTarget.classList.remove("hidden");
        } else {
          this.resultsTarget.innerHTML =
            "<p class='p-2 text-sm text-gray-500'>No patients found.</p>";
          this.resultsTarget.classList.remove("hidden");
        }
      })
      .catch(() => {
        this.resultsTarget.innerHTML =
          "<p class='p-2 text-sm text-gray-500'>Error fetching patients.</p>";
        this.resultsTarget.classList.remove("hidden");
      });
  };

  buildResultsHtml = (patients) => {
    return patients
      .map(
        (patient) => `
      <div class="patient-result cursor-pointer hover:bg-gray-100 p-2" data-action="click->patient-search-select#selectPatient" data-patient-id="${patient.id}">
        <div class="font-medium text-gray-900">${patient.firstName} ${patient.lastName}</div>
        <div class="text-sm text-gray-500">${patient.email || "No email"}</div>
      </div>
    `,
      )
      .join("");
  };

  selectPatient = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const patientId = event.currentTarget.dataset.patientId;
    const patientName = event.currentTarget.querySelector(".font-medium").textContent.trim();

    this.inputTarget.value = patientName;
    this.patientIdTarget.value = patientId;
    this.resultsTarget.innerHTML = "";
    this.resultsTarget.classList.add("hidden");
  };
}
