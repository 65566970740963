import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["benefitType", "field"];

  connect() {
    this.updateFields();
  }

  updateFields() {
    const selectedType = this.benefitTypeTarget.value;

    this.fieldTargets.forEach((field) => {
      const benefitTypes = field.dataset.benefitTypes.split(" ");

      if (benefitTypes.includes(selectedType)) {
        field.style.display = "";
        field.removeAttribute("aria-hidden");
        // Enable and keep the input values
        field.querySelectorAll("input, select, textarea").forEach((input) => {
          input.disabled = false;
        });
      } else {
        field.style.display = "none";
        field.setAttribute("aria-hidden", "true");
        // Disable and clear the inputs
        field.querySelectorAll("input, select, textarea").forEach((input) => {
          input.value = "";
          input.disabled = true;
        });
      }
    });
  }
}
