import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal"];

  open(event) {
    const eventId = event.currentTarget.getAttribute("data-comment-modal-event-id-param");
    const modal = document.getElementById(`comment-modal-${eventId}`);
    if (modal) {
      modal.classList.remove("hidden");
      modal.classList.add("flex");
    }
  }

  close() {
    this.modalTargets.forEach((modal) => {
      modal.classList.add("hidden");
      modal.classList.remove("flex");
    });
  }

  handleSubmit(event) {
    const success = event.detail.success;

    if (success) {
      console.log("Form submission was successful");
      this.close();
    } else {
      // The form will be replaced with the new one containing errors
    }
  }
}
