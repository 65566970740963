import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["closeButton"];

  static values = {
    closeEndpoint: String,
  };

  isOpen = false;

  connect = () => {
    this.element.addEventListener("transitionend", this.handleTransitionEnd);
    this.open();
  };

  disconnect = () => {
    this.element.removeEventListener("transitionend", this.handleTransitionEnd);
  };

  close = () => {
    if (!this.isOpen) {
      return;
    }
    this.isOpen = false;

    if (this.closeEndpointValue) {
      this.closeButtonTarget.click();
    }
    document.body.style.overflow = "";
    this.element.dataset.open = "false";
  };

  closeIfOutside = (event) => {
    if (event.target === this.element) {
      this.close();
    }
  };

  handleTransitionEnd = (event) => {
    if (event.propertyName === "opacity" && this.element.dataset.open === "false") {
      this.element.classList.add("hidden");
    }
  };

  open = () => {
    if (this.isOpen) {
      return;
    }
    this.isOpen = true;

    document.body.style.overflow = "hidden";
    this.element.classList.remove("hidden");
    // setTimeout to ensure the display change has taken effect
    setTimeout(() => {
      this.element.dataset.open = "true";
    }, 0);
  };
}
