import { Controller } from "@hotwired/stimulus";
import { isSamePage } from "~/util/url";

export default class extends Controller {
  static values = {
    path: String,
    class: String,
  };

  connect = () => {
    this.updateVisibility();
    document.addEventListener("turbo:load", this.updateVisibility);
  };

  disconnect = () => {
    document.removeEventListener("turbo:load", this.updateVisibility);
  };

  updateVisibility = (event) => {
    const url = event?.detail?.url || window.location.href;
    if (isSamePage(url, this.pathValue)) {
      this.element.classList.add(...this.classValue.split(" "));
    } else {
      this.element.classList.remove(...this.classValue.split(" "));
    }
  };
}
