// app/javascript/controllers/payment_form_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "amount",
    "submit",
    "error",
    "allocation",
    "patientCreditBalanceCheckbox",
    "paymentType",
  ];

  connect() {
    this.validateAmount();
  }

  togglePatientCreditBalanceUsage() {
    const useCreditBalance = this.patientCreditBalanceCheckboxTarget.checked;
    const creditBalance = parseFloat(this.amountTarget.dataset.patientCreditBalance) || 0;
    if (useCreditBalance) {
      const creditBalanceAmountToUse = Math.min(creditBalance, this.amountTarget.dataset.remaining);
      this.amountTarget.value = creditBalanceAmountToUse.toFixed(2);
      this.paymentTypeTarget.value = "Credit Balance";
    } else {
      this.paymentTypeTarget.value = "Cash";
    }
    this.validateAmount();
  }

  typeChange() {
    console.log("typeChange", this.paymentTypeTarget.value);
    if (this.paymentTypeTarget.value === "Credit Balance") {
      this.patientCreditBalanceCheckboxTarget.checked = true;
      this.togglePatientCreditBalanceUsage();
    }
  }

  validateAmount() {
    const paymentAmount = parseFloat(this.amountTarget.value) || 0;
    const remainingBalance = parseFloat(this.amountTarget.dataset.remaining) || 0;
    const creditBalance = parseFloat(this.amountTarget.dataset.creditBalance) || 0;
    const useCreditBalance = this.patientCreditBalanceCheckboxTarget?.[0]?.checked || false;

    let totalAllocated = 0;
    this.allocationTargets.forEach((input) => {
      totalAllocated += parseFloat(input.value) || 0;
    });

    if (paymentAmount === 0) {
      this.submitTarget.disabled = true;
      this.errorTarget.textContent = "Please enter a payment amount.";
      this.errorTarget.hidden = false;
      return;
    }

    if (useCreditBalance && paymentAmount > creditBalance) {
      this.submitTarget.disabled = true;
      this.errorTarget.textContent = "Payment amount exceeds the patient's credit balance.";
      this.errorTarget.hidden = false;
      return;
    }

    if (paymentAmount > remainingBalance) {
      this.submitTarget.disabled = true;
      this.errorTarget.textContent = "Payment amount exceeds the remaining balance.";
      this.errorTarget.hidden = false;
      return;
    }

    if (totalAllocated > paymentAmount) {
      this.submitTarget.disabled = true;
      this.errorTarget.textContent = "Allocated amounts exceed the payment amount.";
      this.errorTarget.hidden = false;
      return;
    }

    this.submitTarget.disabled = false;
    this.errorTarget.hidden = true;
  }
}
