import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  add(event) {
    const hiddenInput = document.createElement("input");
    hiddenInput.type = "hidden";
    hiddenInput.name = event.params.name;
    hiddenInput.value = event.params.value;
    this.element.appendChild(hiddenInput);
  }
}
