import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="billing"
export default class extends Controller {
  static targets = ["invoice", "toggleButton"];
  static values = {
    allExpanded: Boolean,
  };

  connect = () => {
    this.allExpandedValue = false;
  };

  toggleAll = () => {
    if (this.allExpandedValue) {
      this.collapseAll();
    } else {
      this.expandAll();
    }
  };

  expandAll = () => {
    this.invoiceTargets.forEach((element) => {
      const controller = this.application.getControllerForElementAndIdentifier(
        element.querySelector('[data-controller="invoice"]'),
        "invoice",
      );
      if (controller) {
        controller.expand();
      }
    });
    this.allExpandedValue = true;
    this.toggleButtonTarget.textContent = "Collapse All";
  };

  collapseAll = () => {
    this.invoiceTargets.forEach((element) => {
      const controller = this.application.getControllerForElementAndIdentifier(
        element.querySelector('[data-controller="invoice"]'),
        "invoice",
      );
      if (controller) {
        controller.collapse();
      }
    });
    this.allExpandedValue = false;
    this.toggleButtonTarget.textContent = "Expand all";
  };
}
