import { Controller } from "@hotwired/stimulus";
import { Calendar } from "@fullcalendar/core";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import { Turbo } from "@hotwired/turbo-rails";
import { patch } from "@rails/request.js";
import { toISODateString, convertTimezone } from "~/util/date";

// Connects to data-controller="calendar"
export default class extends Controller {
  static targets = ["calendar", "dateButton", "dateDisplay", "pinnedAppointments"];
  static values = {
    locationId: Number,
    locationTimezone: String,
    hipaaView: { type: Boolean, default: false },
  };

  droppedEvents = [];

  connect() {
    this.initializeCalendar();
    this.initializePinnedAppointments();
  }

  initializeCalendar() {
    this.calendar = new Calendar(this.calendarTarget, {
      schedulerLicenseKey: "0300162430-fcs-1709102530",
      timeZone: this.locationTimezoneValue,
      plugins: [resourceTimeGridPlugin, interactionPlugin],
      initialView: "resourceTimeGridDay",
      initialDate: this.getInitialDate(),
      editable: true,
      eventDrop: (info) => this.handleEventDrop(info),
      eventResize: (info) => this.handleEventResize(info),
      snapDuration: { minutes: 10 }, // TODO: should be a schedule increment setting (10 or 15 min)
      eventOverlap: false,
      nowIndicator: true,
      scrollTime: "08:00:00", // TODO: should be based on account/location setting
      resources: `/settings/locations/${this.locationIdValue}/operatories.json`,
      // TODO: could use date query param to fetch only events near that date vs all events
      // would need to re-fetch on date change if far away date
      // should always fetch events near current date
      events: `/locations/${this.locationIdValue}/appointments/calendar_events.json`,
      headerToolbar: false,
      allDaySlot: false,
      slotDuration: "00:20:00",
      slotLabelInterval: "01:00",
      slotMinTime: "08:00:00",
      slotMaxTime: "20:00:00",
      expandRows: true,
      height: "auto",
      stickyHeaderDates: "true",
      slotLabelFormat: {
        hour: "numeric",
        minute: "2-digit",
        omitZeroMinute: true,
        meridiem: "short",
      },
      dayHeaderFormat: { weekday: "short", day: "numeric" },
      eventClick: (info) => {
        if (!info.event) return;
        info.jsEvent.preventDefault();
        const dialogContainer = document.getElementById("calendar_appointment_preview_frame");

        const positionDialog = (initialRender) => {
          if (initialRender) {
            // do initial render while hidden so div can get sized
            dialogContainer.style.visibility = "visible";
            dialogContainer.classList.remove("transition-opacity");
            dialogContainer.classList.add("opacity-0");

            // TODO: turn into helper function for future modals
            function frameLoadHandler(event) {
              if (event.target.id === "calendar_appointment_preview_frame") {
                positionDialog();
                document.removeEventListener("turbo:frame-load", frameLoadHandler);
              }
            }
            document.addEventListener("turbo:frame-load", frameLoadHandler);
            return;
          }

          // Calculate available space on each side
          const calendarRect = this.element.getBoundingClientRect();
          const eventRect = info.el.getBoundingClientRect();
          const spaceRight = calendarRect.right - eventRect.right;
          const spaceLeft = eventRect.left;
          const spaceBottom = calendarRect.bottom - eventRect.bottom;
          const viewportHeight = window.innerHeight;
          const dialogHeight = dialogContainer.offsetHeight;
          const dialogWidth = dialogContainer.offsetWidth;

          let left, top;
          if (spaceRight >= dialogWidth) {
            left = eventRect.right + 10;
            top = Math.min(eventRect.top, viewportHeight - dialogHeight);
          } else if (spaceLeft >= dialogWidth) {
            left = eventRect.left - dialogWidth - 10;
            top = Math.min(eventRect.top, viewportHeight - dialogHeight);
          } else {
            left = Math.max(0, (eventRect.left + eventRect.right - dialogWidth) / 2);
            if (spaceBottom >= dialogHeight) {
              top = eventRect.bottom + 10;
            } else {
              top = Math.max(0, eventRect.top - dialogHeight - 10);
            }
          }

          // Set the position
          dialogContainer.style.left = `${left}px`;
          dialogContainer.style.top = `${top}px`;
          dialogContainer.classList.add("transition-opacity");
          dialogContainer.classList.remove("opacity-0");
        };

        Turbo.visit(info.event.url, { frame: "calendar_appointment_preview_frame" });
        const patientId = info.event.extendedProps.patientId;
        this.setPatientPanel(patientId);
        this.setActiveAppointmentId(info.event.id);
        positionDialog(true);
      },
      eventDragStart: (info) => {
        this.closeAppointmentPreview();
        const patientId = info.event.extendedProps.patientId;
        this.setPatientPanel(patientId);
      },
      eventContent: (arg) => {
        const isActive = arg.event.id === this.getActiveAppointmentId();
        const eventDuration = arg.event.end.getTime() - arg.event.start.getTime();
        const isShortEvent = eventDuration <= 30 * 60 * 1000; // 30 minutes in milliseconds
        const hasNotes = arg.event.extendedProps.notes?.length > 0;
        const bgColorHex = !isActive
          ? `background: ${arg.event.extendedProps.backgroundColorHex}`
          : "";
        const pinnedClass = arg.event.extendedProps.isPinned
          ? "opacity-50 border-2 border-dashed border-brand-subtle"
          : "";
        const opacityClass = arg.event.extendedProps.status === "completed" ? "opacity-60" : "";
        const activeAppointmentClass = isActive ? "bg-brand-active" : "";
        const primaryTextColor = isActive
          ? "text-typography-primary-dark"
          : "text-typography-primary";
        const secondaryTextColor = isActive
          ? "text-typography-secondary-dark"
          : "text-typography-secondary";
        const tasksBorderClass = arg.event.extendedProps.hasTasks
          ? "border-2 border-brand-warning"
          : "";

        const commonClasses =
          "transition duration-300 ease-in-out hover:shadow-md rounded-xl flex overflow-hidden h-full w-full";

        // todo: ideally we use heroicon or some common component for this "document-text" icon
        const notesIcon = hasNotes
          ? `
          <div class="w-3 ${!isShortEvent ? "h-3" : "ml-1"} ${primaryTextColor}" data-controller="tooltip" data-tippy-content="${arg.event.extendedProps.notes}">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4">
              <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
            </svg>
          </div>
        `
          : "";

        if (isShortEvent) {
          const maxWidthClass = hasNotes ? "max-w-[70%]" : "max-w-[80%]";
          return {
            html: `
              <div class="px-4 py-1 items-center ${commonClasses} ${tasksBorderClass} ${pinnedClass} ${opacityClass} ${activeAppointmentClass}" style="${bgColorHex}">
                  <div class="mr-2 ${arg.event.extendedProps.procedures ? maxWidthClass : ""}">
                    ${
                      !this.hipaaViewValue
                        ? `<div class="${primaryTextColor} text-sm font-semibold truncate">${arg.event.extendedProps.patientName}</div>`
                        : ""
                    }
                  </div>
                ${
                  arg.event.extendedProps.procedures
                    ? `
                      <div
                        class="flex items-center justify-end space-x-1 overflow-hidden min-w-[20%] ml-auto ${hasNotes && !this.hipaaViewValue ? "max-w-[20%]" : ""}"
                        data-controller="tooltip"
                        data-tippy-content="${arg.event.extendedProps.procedures}"
                      >
                        <div class="${secondaryTextColor} text-xs truncate">${arg.event.extendedProps.procedures}</div>
                      </div>
                      `
                    : ""
                }
                ${notesIcon}
              </div>
            `,
          };
        } else {
          return {
            html: `
              <div class="px-4 py-2 flex-col justify-between ${commonClasses} ${tasksBorderClass} ${pinnedClass} ${opacityClass} ${activeAppointmentClass}" style="${bgColorHex}">
                <div class="flex justify-between items-start">
                  ${
                    !this.hipaaViewValue
                      ? `<div class="${primaryTextColor} text-sm font-semibold truncate">${arg.event.extendedProps.patientName}</div>`
                      : "<div></div>"
                  }
                  ${notesIcon}
                </div>
                <div class="flex justify-between items-end mt-1">
                  <div class="${secondaryTextColor} text-xs truncate">${arg.timeText}</div>
                  <div class="flex items-center space-x-1 truncate">
                    <div
                      class="${secondaryTextColor} text-xs truncate"
                      data-controller="tooltip"
                      data-tippy-content="${arg.event.extendedProps.procedures}"
                    >
                      ${arg.event.extendedProps.procedures}
                    </div>
                  </div>
                </div>
              </div>
            `,
          };
        }
      },
      eventDataTransform: (appointment) => {
        return {
          id: appointment.id,
          title: appointment.patientName,
          start: appointment.start,
          end: appointment.end,
          allDay: appointment.allDay,
          resourceId: appointment.resourceId,
          url: appointment.previewUrl,
          extendedProps: {
            backgroundColorHex: appointment.backgroundColorHex,
            hasTasks: appointment.hasTasks,
            isPinned: appointment.isPinned,
            notes: appointment.notes,
            patientId: appointment.patientId,
            patientName: appointment.patientName,
            procedures: appointment.procedures,
            status: appointment.status,
          },
        };
      },
      datesSet: () => {
        this.updateDateDisplay();
        this.closeAppointmentPreview();
        this.broadcastDateChanged();
        this.updateQueryParam();
      },
      droppable: true,
      eventReceive: (info) => {
        this.droppedEvents.push(info.event);
        this.updateAppointment(info.event);
      },
      eventSourceSuccess: () => {
        this.droppedEvents.forEach((event) => {
          event.remove();
        });
      },
      eventDidMount: (info) => {
        // We should only need to check the events that mounted in the calendar since thats what the user is looking at
        if (info.event.id === this.getActiveAppointmentId()) {
          const patientId = info.event.extendedProps.patientId;
          this.setPatientPanel(patientId);
        }
      },
    });

    this.calendar.render();
  }

  broadcastDateChanged() {
    this.dispatch("date-changed", {
      detail: { date: this.getCurrentDate() },
    });
  }

  getDateParam() {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("date");
  }

  getInitialDate() {
    const dateParam = this.getDateParam();
    if (!dateParam || dateParam === "today") {
      return toISODateString(new Date());
    }
    return dateParam;
  }

  updateQueryParam() {
    const currentDate = toISODateString(this.getCurrentDate());
    const today = toISODateString(new Date());

    const param = currentDate === today ? "today" : currentDate;

    const url = new URL(window.location);
    url.searchParams.set("date", param);
    window.history.replaceState({}, "", url);
  }

  toggleHipaaView() {
    this.hipaaViewValue = !this.hipaaViewValue;
    this.rerenderCalendar();
  }

  setPatientPanelAction = (event) => {
    const patientId = event.params.patientId;
    this.setPatientPanel(patientId);
  };

  setPatientPanel = (patientId) => {
    Turbo.visit(`/patients/${patientId}/panel`, { frame: "panel_frame" });
    this.rerenderCalendar();
  };

  setActiveAppointmentId(appointmentId) {
    const url = new URL(window.location);
    if (appointmentId) {
      url.searchParams.set("appointment_id", appointmentId);
    } else {
      url.searchParams.delete("appointment_id");
    }
    window.history.replaceState({}, "", url);
    this.rerenderCalendar();
  }

  handleEventDrop = (info) => {
    this.updateAppointment(info.event);
  };

  handleEventResize = (info) => {
    this.updateAppointment(info.event);
  };

  updateAppointment = (event) => {
    const appointmentId = event.id;
    const resourceId = event.getResources()[0].id;
    // Used for when fullcalendar returns a date without timezone offset (i.e. when rescheduling an appointment)
    const startTimeWithTimezone = convertTimezone(event.start, this.locationTimezoneValue);

    return patch(`/appointments/${appointmentId}/schedule`, {
      body: {
        appointment: {
          start_time: startTimeWithTimezone,
          duration_minutes: Math.round((event.end - event.start) / (1000 * 60)),
          operatory_id: resourceId,
        },
      },
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    })
      .then((response) => response.text)
      .then((html) => {
        Turbo.renderStreamMessage(html);
      });
  };

  goToDate(event) {
    const newDate = event.detail.date;
    const currentDate = this.getCurrentDate();
    if (newDate.toDateString() !== currentDate?.toDateString()) {
      this.calendar.gotoDate(newDate);
    }
  }

  // Full calendar returns back a date with the timezone offset set from initialization so we need
  // to adjust for the timezone offset of the user's browser
  getCurrentDate = () => {
    let currentDate = this.calendar.getDate();
    const timezoneOffset = currentDate.getTimezoneOffset();
    const adjustedDate = new Date(currentDate.getTime() + timezoneOffset * 60000);
    return adjustedDate;
  };

  updateDateDisplay() {
    const currentDate = this.getCurrentDate();
    let formattedDate;
    if (currentDate.toDateString() === new Date().toDateString()) {
      formattedDate = `Today, ${currentDate.toLocaleString("default", { month: "short", day: "numeric" })}`;
    } else {
      formattedDate = currentDate.toLocaleString("default", {
        weekday: "long",
        month: "short",
        day: "numeric",
      });
    }
    this.dateDisplayTarget.textContent = formattedDate;
  }

  eventData(info) {
    return {
      "appointment[start_time]": info.event.start,
      "appointment[end_time]": info.event.end,
      "appointment[operatory_id]": info.event.getResources()[0].id,
      "appointment[status]": info.event.status,
    };
  }

  closeAppointmentPreview() {
    // TODO: figure out proper way to clear
    const previewDialog = document.getElementById("calendar_appointment_preview_frame");
    if (previewDialog) {
      previewDialog.innerHTML = "";
    }
  }

  refetchEvents() {
    this.calendar.refetchEvents();
  }

  rerenderCalendar() {
    // Immediately forces the calendar to render and/or readjusts its size
    this.calendar.render();
  }

  goToToday() {
    this.calendar.today();
  }

  next() {
    this.calendar.next();
  }

  prev() {
    this.calendar.prev();
  }

  getActiveAppointmentId() {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("appointment_id");
  }

  initializePinnedAppointments() {
    new Draggable(this.pinnedAppointmentsTarget, {
      itemSelector: "[data-pinned-appointment]",
    });
  }
}
