import { Controller } from "@hotwired/stimulus";
import { computePosition, offset, flip, shift } from "@floating-ui/dom";

export default class extends Controller {
  static targets = ["trigger", "popover", "focus"];
  static values = { placement: String };

  connect = () => {
    this.transferTriggerAttributes();
    this.isOpen = false;
    this.addEventListeners();
  };

  disconnect = () => {
    document.removeEventListener("click", this.handleClickOutside);
    this.observer.disconnect();
  };

  addEventListeners = () => {
    document.addEventListener("click", this.handleClickOutside);
    this.observer = new MutationObserver(this.handleDOMMutation);
    this.observer.observe(this.element, { childList: true, subtree: true });
  };

  handleDOMMutation = (mutations) => {
    for (const mutation of mutations) {
      if (mutation.type === "childList") {
        // turbo morphing can re-render the popover without causing connect to be called again
        // in that case, need to manually transfer trigger attributes again
        if (this.element.querySelector("[data-should-transfer-trigger]")) {
          this.transferTriggerAttributes();
          break;
        }
      }
    }
  };

  transferTriggerAttributes = () => {
    const realTriggerTarget = this.triggerTarget.children[0];
    realTriggerTarget.setAttribute(
      "data-action",
      `${realTriggerTarget.getAttribute("data-action") || ""} ${this.triggerTarget.getAttribute("data-action")}`.trim(),
    );
    realTriggerTarget.setAttribute(
      `data-${this.identifier}-target`,
      this.triggerTarget.getAttribute(`data-${this.identifier}-target`),
    );
    this.triggerTarget.replaceWith(realTriggerTarget);
  };

  togglePopover = () => {
    this.isOpen ? this.closePopover() : this.openPopover();
  };

  openPopover = () => {
    this.isOpen = true;

    this.popoverTarget.classList.remove("hidden");

    computePosition(this.triggerTarget, this.popoverTarget, {
      placement: this.placementValue || "bottom-start",
      middleware: [offset(8), flip(), shift({ padding: 8 })],
      strategy: "fixed",
    }).then(({ x, y }) => {
      Object.assign(this.popoverTarget.style, {
        left: `${x}px`,
        top: `${y}px`,
      });
    });

    if (this.hasFocusTarget) {
      if (this.focusTarget.value) {
        this.focusTarget.value = "";
        this.focusTarget.dispatchEvent(new Event("input"));
      }
      this.focusTarget.focus();
    }
  };

  closePopover = () => {
    this.isOpen = false;
    this.popoverTarget.classList.add("hidden");
  };

  handleClickOutside = (event) => {
    if (
      this.isOpen &&
      !this.popoverTarget.contains(event.target) &&
      !this.triggerTarget.contains(event.target)
    ) {
      this.closePopover();
    }
  };
}
