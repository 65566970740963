import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["details", "icon"];

  initialize = () => {
    this.expanded = false;
  };

  toggle = () => {
    if (this.expanded) {
      this.collapse();
    } else {
      this.expand();
    }
  };

  expand = () => {
    this.detailsTarget.classList.remove("hidden");
    this.iconTarget.querySelector("svg").classList.add("rotate-90");
    this.expanded = true;
  };

  collapse = () => {
    this.detailsTarget.classList.add("hidden");
    this.iconTarget.querySelector("svg").classList.remove("rotate-90");
    this.expanded = false;
  };
}
