import "~/observability/setup";
import "@hotwired/turbo-rails";
import Rails from "@rails/ujs";
import { start as startStorage } from "@rails/activestorage";
import LocalTime from "local-time";

startStorage();
LocalTime.start();

import "../../javascript/channels";
import "../../javascript/controllers";
import.meta.glob("../../javascript/src/**/*", { eager: true });

Rails.start();
