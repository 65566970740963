import { Dropdown, Tabs, Toggle, Slideover } from "tailwindcss-stimulus-components";
import setupUltimateTurboModal from "ultimate_turbo_modal";

import { application } from "./application";

const MAIN_CONTROLLERS = import.meta.glob("./**/*_controller.js", { eager: true });
const COMPONENT_CONTROLLERS = import.meta.glob("../../components/**/*_controller.js", {
  eager: true,
});

function registerControllers(controllers, start) {
  for (const [relativeName, mod] of Object.entries(controllers)) {
    // https://stimulus.hotwired.dev/reference/controllers#identifiers
    // Always slice off the _controller.js part of the filename (-14)
    const name = relativeName.slice(start, -14).replaceAll("_", "-").replaceAll("/", "--");
    application.register(name, mod.default);
  }
}
registerControllers(MAIN_CONTROLLERS, "./".length);
registerControllers(COMPONENT_CONTROLLERS, "../../components/".length);

application.register("dropdown", Dropdown);
application.register("tabs", Tabs);
application.register("toggle", Toggle);
application.register("slideover", Slideover);

import CommentModalController from "./comment_modal_controller";
application.register("comment-modal", CommentModalController);
import PatientHistoryController from "./patient_history_controller";
application.register("patient-history", PatientHistoryController);
import PatientSearchSelectController from "./patient_search_select_controller";
application.register("patient-search-select", PatientSearchSelectController);
import PaymentFormController from "./payment_form_controller";
application.register("payment-form", PaymentFormController);
import InvoiceController from "./invoice_controller";
application.register("invoice", InvoiceController);
import InsuranceBenefitFormController from "./insurance_benefit_form_controller";
application.register("insurance-benefit-form", InsuranceBenefitFormController);
import BillingController from "./billing_controller";
application.register("billing", BillingController);
setupUltimateTurboModal(application);
